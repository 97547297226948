@import './colors';

.quill-fr {
  .ql-toolbar:after {
    content: unset !important;
  }
  .ql-editor {
    min-height: 200px;
  }

  .ql-active,
  button:hover {
    color: $secondary !important;
  }
  .ql-tooltip {
    z-index: 1300;
  }
  .ql-tooltip[data-mode='link'] {
    input::placeholder {
      outline: none !important;
      color: white;
    }
    &::before {
      content: 'Entrer le lien' !important;
    }
  }
  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    content: 'Sauvegarder' !important;
    color: $secondary !important;
  }
}
