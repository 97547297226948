@import './imports';
@import './colors';

body {
  --accent: #{$accent};
  --tbc: #{$tbc};
  --programmed: #{$programmed};
  --not-programmed: #{$not-programmed};
  --event: #{event};
  --secondary: #{$secondary};
  --light: #{$light};
  --grey: #{$grey};
}
